.form {
  padding-bottom: 20px;
  @media (max-width: 720px) {
    padding-bottom: 0;
  }
}

.selectCity {
  max-width: 360px;
  margin-bottom: 16px;
  //TODO: remove after refactoring PlaceAutocomplete.tsx
  &:global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
    border-color: #8f8f8f !important;
    border-width: 1px !important;
  }

  & > div > label {
    color: #8f8f8f !important;
  }

  @media (max-width: 720px) {
    width: 100%;
    padding: 0;
  }
}

.selectMethodShipping {
  max-width: 360px;
  margin-bottom: 16px;

  @media (max-width: 720px) {
    width: 100%;
    padding: 0;
  }
}

.input {
  max-width: 360px;
  margin-bottom: 16px;
  height: 44px;

  //TODO: remove after refactoring PlaceAutocomplete.tsx
  &:global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
    border-color: #8f8f8f !important;
    border-width: 1px !important;
  }

  & > div > label {
    color: #8f8f8f !important;
  }

  @media (max-width: 720px) {
    padding: 0;
  }
}

.title {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 20px;
}

.submitBtn {
  cursor: pointer;
  border: none;
  background-color: #5f6eb2;
  padding: 10px 24px;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;

  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}

.cancelBtn {
  cursor: pointer;
  background-color: transparent;
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-radius: 6px;
  padding: 8px 22px;
  border: 2px solid #5f6eb2;
  margin-left: 16px;
}

.wrapperApartmentAndBuilding {
  display: block;
  @media (max-width: 720px) {
    display: flex;
    gap: 16px;
  }
}