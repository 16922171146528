.btnAddAddress {
  border: 2px solid #5F6EB2;
  background-color: #fff;
  cursor: pointer;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  color: #333;
  margin-bottom: 32px;
  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}
.titleShippingMethod{
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin: 0 0 6px 0;
}
.wrapperCourier,
.wrapperNovaPost{
  margin-bottom: 24px;
}
