.input {
  margin-bottom: 16px;
}

.inputWidth {
  width: 368px;

  @media (max-width: 720px) {
    width: 100%;
  }
}

.addressContainer {
  display: flex;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }

  & > *:first-of-type {
    flex: 5;

    @media (max-width: 720px) {
      flex: auto;
      width: 100%;
      margin-bottom: 12px;
    }
  }

  & > *:not(:first-of-type) {
    flex: 2;
  }

  & > *:not(:first-child) {
    margin-left: 12px;

    @media (max-width: 720px) {
      flex: auto;
      margin-left: 0;
      flex-basis: 40%;
    }
  }

  & > *:last-of-type {
    @media (max-width: 720px) {
      margin-left: 12px;
    }
  }
}

.recipientMethod,
.exactTime {
  display: flex;
  margin-top: 24px;
  margin-bottom: 16px;

  & > *:not(:first-of-type) {
    margin-left: 24px;
  }

  & > * {
    cursor: pointer;
    user-select: none;
    color: #691b33;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding-bottom: 8px;
    border-bottom: 4px solid #fff;
  }

  & > *.recipientActive,
  *.exactTimeActive {
    color: #333;
    border-bottom: 4px solid #691b33;
  }

  .exactTimeDisabled {
    color: #bbb;
    user-select: none;
    cursor: not-allowed;
    border-bottom: none;
  }
}
.timeSelection {
  width: 50%;

  @media (max-width: 720px) {
    width: 100%;
  }
}