button.doneBtn {
  border-radius: 8px;
  background-color: #5f6eb2;
  max-height: 44px;
  & > div {
    height: 27px !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  div.input {
    margin-bottom: 0;
    width: 100%;

    & > input {
      padding: 10px 12px;
      text-transform: uppercase;
    }
  }
}

.appliedPromo {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .promoCode {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: #333;

    &Price {
      color: #FF5C32;
    }

    &Name {
      font-weight: 500;
      line-height: 120%;
    }

    & > * {
      margin: 0;
    }
  }
}

.error {
  width: 100%;
  text-align: left;
  margin: 8px 0 0;
}

.formContainer{
  display: flex;
  width: 100%;
  gap: 16px;
}

.cancelBtn {
  max-height: 44px;
}