.wrapper {
  display: flex;
  flex-direction: column;
  color: #666666;
  font-size: 14px;

  & > div:first-of-type {
    margin-bottom: 26px;

    @media (max-width: 960px) {
      margin: 0;
    }
  }

  @media (max-width: 960px) {
    flex-direction: row;
    flex-wrap: wrap;
    border-bottom: 1px solid #ebe4e4;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 29px;

    & > div:last-child {
      width: 100%;
    }
  }
}

.footerSocialLinks {
  margin-bottom: 24px;
  @media (max-width: 720px) {
    margin-bottom: 0;
  }
}
