@import '@layout/z-index-layouts.scss';

.root {
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  height: 62px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: $zindex-mobile-footer-nav;
  bottom: 0;
  top: unset;
  width: 100vw;
}

// special height for iOS devices (service bottom padding)
.safeBottom {
  @media (max-width: 960px) {
    height: calc(62px + env(safe-area-inset-bottom));
    align-items: flex-start;
    padding-top: 12px;
  }
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 3px;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  margin: 0;
  color: #333;

  .text {
    margin-top: 6px;
  }

  & .linkIcon {
    stroke: #000;
    stroke-width: 2px;
  }

  &.active {
    color: #ff5c32;

    & .linkIcon {
      stroke: #ff5c32;
      stroke-width: 2px;
    }

    & .linkIconFill {
      fill: #ff5c32;
    }

    & :global(.text) {
      display: inline-block;
    }
  }
}

.mobileModalRoot {
  z-index: $zindex-modal-more;
}

.mobileModal {
  width: 100vw;
  height: inherit;
  margin-bottom: 62px;
  margin-top: 58px;
  padding: 25px 12px;
  box-shadow: inset 0 0 12px rgb(0 0 0 / 16%);
  &.mobileModalWithAppNotification {
    margin-top: 100px;
  }
}

.innerMobileModal {
  width: 100vw;
  height: auto;
  background-color: #fff;
  margin-top: 58px;
  box-shadow: inset 0 0 12px rgb(0 0 0 / 16%);
  padding-bottom: 124px;

  // &.showNotification {
  //   margin-top: 114px;
  // }
  &.mobileModalWithAppNotification {
    margin-top: 100px;
  }
  &.mobileModalTop {
    z-index: $zindex-drawer;
  }
  &.fullHeightDrawer {
    width: 100vw;
    margin-top: 57px;
    height: auto;
  }
}

.innerFullHeightDrawer {
  display: grid;
}

.fullHeightDrawer {
  width: 100vw;
  height: calc(100% - env(safe-area-inset-bottom));
  margin-top: 57px;
  margin-bottom: env(safe-area-inset-bottom);
}

.mobileModalTop {
  height: calc(100% - env(safe-area-inset-bottom));
  top: 0;
}

// .showNotification {
//   height: calc(100% - 62px);
// }

.navigationList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  .menuItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 171px;
    max-width: calc(50% - 5px);
    margin-bottom: 16px;
    white-space: nowrap;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    line-height: 22px;
    @media (min-width: 720px) {
      cursor: pointer;
    }

    .itemImage {
      position: relative;
      height: 94px;
      margin-bottom: 12px;
      border-radius: 8px;
      overflow: hidden;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > a {
      text-decoration: none;
    }
  }
}

.innerNavigationList {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  margin: 0;

  .categorySubTitleLink {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #333333;
    display: flex;
    align-items: center;

    .childrenName {
      margin: 0;
      font-weight: 500;
      line-height: 120%;
    }
  }

  .itemContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .innerMenuImage {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin-right: 16px;
    }
  }
}

.viewAll {
  color: #691b33;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0 12px;
  padding-bottom: 2px;
  border-bottom: 1px solid #691b33;
}

.cartIconQty {
  position: absolute;
  top: -8px;
  left: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ff5c32;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
}

.favoritesIcon {
  position: absolute;
  top: -1px;
  left: 54%;
  background: #ff5c32;
  border: 2px solid #fff;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

img.offers {
  height: 94px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.offersTitle {
  color: #ff5c32;
}

.container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.profileItem {
  min-width: 20%;
}
