@import "@layout/z-index-layouts.scss";

.resetAllButton {
  display: inline-block;
  padding-bottom: 4px;
  margin-bottom: 4px;
  margin-left: 16px;
  white-space: nowrap;
}

.sortingSelect {
  margin-right: -10px;
}

.wrapperSortingSelect {
  color: #333;
  z-index: $zindex-sorting-bar;
  letter-spacing: 0.5px;
}

.wrapperTopBar {
  margin-left: 18px;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    margin-left: 0;
  }
}

.selectedFilters {
  display: flex;
  align-items: center;
  @media (max-width: 720px) {
    display: block;
    overflow: hidden;

    & > :first-of-type {
      margin-bottom: 0;
      display: flex;
      overflow-x: scroll;
      align-items: center;
    }
  }
}

.chipList {
  overflow: hidden;
  flex-wrap: wrap;
  display: flex;
  gap: 8px;
  margin: 0;
}

div.sortOrder {
  flex-shrink: 0;
}

.topBarFiltersWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  & > * {
    display: flex;

    &:first-of-type {
      flex-grow: 1;
      @media (max-width: 720px) {
        margin: 0;
      }
    }

    &:last-child {
      margin-right: 12px;
    }
  }

  &.device {
    & > * {
      &:first-of-type {
        margin-left: 32px;
      }

      &:last-child {
        margin-right: 32px;
      }
    }
  }

  &.mobile {
    justify-content: flex-end;
  }
}
