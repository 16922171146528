.navigationMenuContainer {
  max-width: 1920px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 21px 20px;
  &.menuScrolled {
    padding: 0 20px;
  }
}

.nav {
  width: 100%;
}

.navigationList {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  > li {
    margin-right: 28px;
    font-weight: 500;
    line-height: 20px;
    font-size: 18px;
    color: #333333;
  }

  .categoryTitleLink {
    white-space: nowrap;

    cursor: pointer;
    padding: 20px 0;

    &:hover {
      color: #691b33;
    }

    > a {
      text-decoration: none;
    }
  }

  .specialCategoryTitleLink {
    color: #ff5c32;
  }

  .titleLink {
    font-size: 15px;
    line-height: 16px;
    padding: 0;
  }

  .offersItem {
    color: #ff5c32;

    &:hover {
      color: #691b33;
    }
  }

  .popupContent {
    overflow: hidden;
    min-height: 100%;

    .containerCategories {
      padding-bottom: 2px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      overflow: auto;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  .containerCategories {
    padding-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: auto;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.categorySubTitleLink {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #333333;
}

.itemContainer {
  display: flex;
  align-items: center;
  width: max-content;

  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 16px;
  }
}

.children {
  color: #333;
}

.popupMenuContainer {
  display: grid;
  list-style-type: none;
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  will-change: opacity, transform;
  z-index: 100;
  border-radius: 8px;
  border-color: transparent;
  padding: 32px 24px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.16);
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  background: #fff;
}

.popupMenuArrow {
  height: 20px;
  width: 150px;
  overflow: hidden;
  position: absolute;
  background: transparent;
  color: rgb(255, 255, 255);
  z-index: -1;
  transform: rotate(0deg) translateY(-100%) translateX(-50%);
  top: 0%;
  padding-top: 11px;
}
