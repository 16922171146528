.wrapper {
  background-color: #ebe4e4;
  padding: 0 0 80px 0;
  @media (max-width: 720px) {
    padding: 0;
  }
}

.container {
  background-color: #ffffff;
  margin: 0 auto;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  @media (max-width: 720px) {
    flex-direction: column;
    width: 100%;
  }
}

.wrapperSelected {
  width: 540px;
  @media (max-width: 720px) {
    width: 100%;
    padding-bottom: 20px;
  }
}

.mapContainer {
  height: 500px;
  border-radius: 0 8px 8px 0;
  background: #d9d9d9;
  width: 100%;
  @media (max-width: 720px) {
    border-radius: 0 0 8px 8px;
    height: 350px;
  }
}

.select {
  border-radius: 12px;
  padding: 40px 32px 0 32px;
  @media (max-width: 720px) {
    padding: 20px 12px 0 12px;
  }
}
