.actionButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.shippingData {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 6px 0;
}

.default {
  margin: 0;
  color: #999;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}