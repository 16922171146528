.wrapperCard {
  margin-bottom: 20px;
  padding: 32px;

  @media (max-width: 720px) {
    padding: 24px 12px;
  }
}

.container {
  margin-top: 32px;

  @media (max-width: 720px) {
    margin-top: 24px;
  }
}

.title {
  color: #333;
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.disabled {
    color: #999999;
  }

  .titleLabel {
    flex: 1;
  }
}

.fullWidth {
  width: 100%;
  margin-top: 10px;
}

.radioFormControlLabel {
  align-items: flex-start;
  margin-right: 0;
  margin-bottom: 14px;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  & > *:first-of-type {
    display: flex;
    flex-direction: column;
  }

  .labelTitle {
    font-size: 16px;
  }

  .labelPrice {
    color: #999a99;
    font-size: 12px;
  }

  .labelDate {
    font-size: 14px;

    @media (max-width: 720px) {
      font-size: 12px;
      text-align: right;
    }
  }
}

button.submitBtn {
  margin-top: 32px;
  @media (max-width: 720px) {
    margin-top: 24px;
  }
}

.deliveryCompleted {
  display: flex;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-top: 32px;

  & > * {
    &:first-of-type {
      width: 45%;
      color: #9a9999;

      @media (max-width: 720px) {
        margin-right: 12px;
      }
    }
  }
}

.deliveryDateContainer {
  display: grid;
  width: 53%;
  @media (max-width: 720px) {
    width: 100%;
  }
}
