.container {
  min-height: calc(100% - 60px);
  @media (max-width: 720px) {
    height: 100vh;
  }
}

.searchHeader {
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin: 0 12px;
  border-bottom: 1px solid #ebe4e4;

  @media (min-width: 720px) {
    padding: 16px 12px;
    margin: 0;
    border-bottom: none;
  }

  .searchHeaderIcon {
    stroke: #333333;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }

  &.searchHeaderSqueezed {
    padding: 8px 12px;
  }
}

.searchInput {
  border: none;
  outline: none;
  flex: 1;
  margin: 0 14px;

  &::placeholder {
    color: #999999;
    font-weight: 400;
  }
}

.innerContainer {
  @media (min-width: 720px) {
    padding: 0 16px;
    background-color: #fff;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0px 8px 20px 0px rgba(60, 21, 33, 0.06);
  }
}

.listContainer {
  padding: 0 12px;

  & > *:not(:last-of-type) {
    border-bottom: 1px solid #ebe4e4;
  }

  @media (min-width: 720px) {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.historyContainer {
  padding: 16px 0;
}

.historyItemsWrapper {
  display: flex;
  flex-direction: column;
}

.searchHistoryItem {
  color: #333;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  stroke: #333333;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  display: inline-flex;
  align-items: center;
  padding: 4px 0;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    color: #bc6f87;
  }
}

.productsContainer {
  padding: 16px 0;
}

.productsItem {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.imgProduct {
  width: 50px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.productName {
  margin-left: 12px;
  flex: 1;
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  .productsItemHighlight {
    color: #ff5c32;
  }
}

.categoriesContainer {
  padding: 16px 0;
}

.catagoriesItemsWrapper {
  display: flex;
  flex-direction: column;
}

.categoriesItem {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding: 4px 0;
  margin-bottom: 8px;

  & > * {
    color: #999;
  }
}

.groupTitle {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 16px 0;
}

.emptyList {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 60px);
  margin: 0;

  @media (min-width: 720px) {
    padding: 24px 0;
  }
}

.showAll {
  padding-bottom: 24px;
  text-align: center;

  &Link {
    color: #691b33;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    border-bottom: 1px solid #691b33;

    &:hover {
      color: #bc6f87;
      border-color: #bc6f87;
    }
  }
}
