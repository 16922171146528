.container {
  border-radius: 12px;
  background-color: #fff;
  padding: 12px 20px 12px 12px;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.reply {
  margin-top: 24px;
  border-top: 1px solid #EBE4E4;

  .replyInfo {
    display: flex;
    align-items: center;
    margin: 24px 0;

    .replyFromFlowerpot {
      color: #FF5C32;
      font-size: 16px;
      font-weight: 700;
      line-height: 120%;
      margin-left: 12px;
    }
  }
}

.date {
  margin: 0 0 0 auto;
  color: #999;
  font-size: 14px;
  font-weight: 400;
}

.message {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  white-space: pre-line;
}

.previewImage {
  position: relative;
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 6px;
  margin: 0;
  margin-right: 16px;

  &.containerImage {
    width: 80px;
    height: 80px;
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.feedbackTitle {
  color: #333;
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  margin: 0 24px 0 0;
  @media (max-width: 720px) {
    margin: 0 12px 0 0;
  }
}

.star {
  margin-bottom: 0;
  gap: 4px;
}

.infoProductContainer {
  margin-right: auto;

  .productName {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    margin: 0 0 8px 0;
  }

  .productSize {
    color: #333;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.btn {
  border: 2px solid #5f6eb2;
  padding: 15px 44px;
  border-radius: 6px;
  text-align: center;
  font-weight: 700;
  color: #333;
  font-size: 14px;
  background: transparent;
  cursor: pointer;

  &:hover {
    color: initial;
    border-color: initial;
  }

  @media (max-width: 720px) {
    width: 100%;
    margin-top: 12px;
    justify-content: center;
  }
}

.imgContainer {
  display: flex;
  gap: 20px;
  @media (max-width: 720px) {
    overflow: scroll;
  }

  .itemImage {
    position: relative;
    height: 92px;
    width: 92px;
    overflow: hidden;
    border-radius: 6px;
    margin: 0;

    .imgFeedback {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.feedbackContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .feedbackItem {
    display: flex;
  }
}

.link {
  cursor: pointer;
  display: flex;
  align-items: center;
}