
.triggerAddressDialog {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #333333;
  white-space: nowrap;
}

.dialogTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #333333;
  text-align: center;
  margin: 0 0 12px;
}


.choiceCity {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #333333;
}

.arrowExpand {
  transform: rotate(180deg);

  & > svg {
    height: 18px;
  }
}
