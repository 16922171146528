$breakpoint-md: 960px;

.featuredCategoriesWrapper {
  padding: 0 20px;

  @media (max-width: 720px) {
    padding: 0 12px;
  }

  .featuredCategoriesContainer {
    max-width: 1760px;
    margin: 0 auto;
    padding: 80px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 7.7vw);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    & > [class*="categoryCard"] {
      border-radius: 12px;
      position: relative;
      overflow: hidden;

      .categoryTitle {
        position: absolute;
        bottom: 32px;
        right: 32px;
        font-weight: 700;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: 0.01em;
        color: #fff;
        margin: 0;

        @media (max-width: 720px) {
          bottom: 20px;
          right: 19px;
        }
      }

      .image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .categoryCard0 {
      grid-area: 1 / 1 / 3 / 2;
    }

    .categoryCard1 {
      grid-area: 3 / 1 / 6 / 2;
    }

    .categoryCard2 {
      grid-area: 1 / 2 / 6 / 3;
    }

    .categoryCard3 {
      grid-area: 1 / 3 / 4 / 4;
    }

    .categoryCard4 {
      grid-area: 4 / 3 / 6 / 4;
    }

    @media (min-width: 1920px) {
      grid-template-rows: repeat(5, 148px);
    }

    @media (max-width: $breakpoint-md) {
      padding: 60px 0 0;
      margin-bottom: 60px;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(5, 193px);

      .categoryCard0 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .categoryCard1 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .categoryCard2 {
        grid-area: 3 / 1 / 4 / 2;
      }

      .categoryCard3 {
        grid-area: 4 / 1 / 5 / 2;
      }

      .categoryCard4 {
        grid-area: 5 / 1 / 6 / 2;
      }
    }
  }

}