.root {
  border-bottom: 1px solid #ebe4e4;
}

.labelContainer {
  padding: 20px 0;
}

.linkItem {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.groupTitle {
  font-size: 16px;
  margin-top: 0;
  font-weight: 700;
  line-height: 20px;

  @media (max-width: 960px) {
    margin: 0;
    margin-right: auto;
  }
}

.list {
  list-style: none;
  font-size: 16px;
  padding-left: 0;

  & > li {
    color: #333333;
    margin-top: 16px;

    &:first-of-type {
      margin-top: 24px;

      @media (max-width: 960px) {
        margin: 0;
      }
    }
  }

  @media (max-width: 960px) {
    margin: 0;
    font-size: 14px;
  }

  @media (max-width: 960px) {
    padding-bottom: 16px;
  }
}
