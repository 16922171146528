.text {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}

.modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 100px 24px;
}

.sendMore {
  border: 2px solid #5F6EB2;
  padding: 15px 44px;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
}