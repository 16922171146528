.containerCountry{
  display: flex;
  .countryLabel{
    margin: 0 5px 0 10px;
  }
}
.country {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

.currency {
  margin-left: 35px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.address {
  margin-top: 12px;
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.navMenu {
  margin-top: 44px;

  & h3 {
    margin-bottom: 16px;
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }

  & li {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}

.socialLinks {
  margin-top: 44px;
}

.phoneNumber {
  color: #333;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}