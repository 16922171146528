.authTitle {
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.rememberBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .rememberMe {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;

    .checkBox {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }

    .checkBox:hover {
      background: #691b33;
    }

    .checkBox:checked {
      accent-color: #691b33;
    }

    label {
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }
  }
}

.forgotPassword {
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.42px;
}

.middleText {
  position: relative;
  width: 100%;
  margin: 30px 0 40px 0;

  .hr {
    border: 1px solid #ddd;
  }

  .orText {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    margin: 0;
    padding: 0 3px;
    top: 10%;
    color: #333;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
}

.signUp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 45px 0 0 0;

  .signUpTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 0;
  }

  .signUpLink {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.42px;
  }
}

.socialButtons {
  justify-content: space-around;
}