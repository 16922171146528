@import "@layout/z-index-layouts.scss";

$shadow-cardHover: 0px 5px 15px rgba(0, 0, 0, 0.08);

.drawerRoot {
  z-index: $zindex-modal;
}

.mobileModal {
  width: 100vw;
  height: 100vh;
  z-index: $zindex-modal;
}

.filterLabel {
  color: #691b33;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-right: auto;
  //@todo: remove after refactoring ExpandPanel
  &:hover {
    background-color: #ebe4e4
  }

  @media (max-width: 375px) {
    margin-left: 8px;
  }
  @media (max-width: 275px) {
    margin-left: 0;
  }
}

.filterSortContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 27px;

  @media (max-width: 720px) {
    margin-bottom: 5px;
    justify-content: space-between;
  }

  @media (max-width: 360px) {
    align-items: flex-start;
    flex-direction: column;
  }
  @media (max-width: 275px) {
    align-items: flex-end;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  padding: 16px 12px;
  border-bottom: 1px solid #ebe4e4;

  .closeModalIcon {
    position: absolute;
    left: 12px;
  }

  .modalTitle {
    flex: 1;
    text-align: center;
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: 0.2px;
    @media (max-width: 300px) {
      text-align: left;
      margin-left: 80px;
    }
    @media (max-width: 280px) {
      margin-left: 60px;
    }
    @media (max-width: 260px) {
      margin-left: 36px;
    }
  }

  .resetFiltersBtn {
    padding: 0;
    position: absolute;
    right: 16px;
    color: #691b33;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border-bottom: 1px solid #691b33;
    border-radius: 0;
    height: 23px;
    @media (max-width: 300px) {
      flex: 1;
    }
  }
}

button.showResult {
  @media (max-width: 300px) {
    width: 100%;
  }
}

.filtersFooter {
  position: sticky;
  z-index: $zindex-mobile-filters-actions;
  bottom: 0;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0px -4px 12px 0px rgba(60, 16, 29, 0.12);
  width: 100%;
  @media (max-width: 300px) {
    flex-wrap: wrap;
    gap: 4px;
  }

  .totalDocs {
    display: flex;
    align-items: center;

    & > span:first-of-type {
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    & > span:last-of-type {
      color: #333;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-left: 10px;
    }
  }
}
