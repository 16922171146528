.ul {
  list-style: none;
  margin: 0;

  &.hasChildren {
    padding-left: 23px;
    position: relative;
  }
}

.arrow {
  transition: transform 0.2s ease-in-out;
  transform: rotate(90deg);
  cursor: pointer;

  & > * {
    display: flex;
  }

  &.arrowExpand {
    transform: rotate(180deg);
    transition: transform 0.2s ease-in-out;
  }
}

.wrapper {
  padding: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.container {
  display: flex;
  align-items: center;
}
