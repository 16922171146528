.statusFilterOption {
  .filterTitle {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-top: 0;
  }

  .filterItems {
    display: flex;
    flex-direction: column;
    padding-left: 6px;
    padding-right: 0;

    & > label {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      vertical-align: middle;
      -webkit-tap-highlight-color: transparent;
      margin-left: -11px;
      margin-right: 16px;
      & > span {
        color: #691b33;
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        outline: 0px;
        border: 1px;
        margin: 0px;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-decoration: none;
        padding: 5px;
        opacity: 1;
      }
      & > span:nth-child(2) {
        margin: 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.4;
        color: #333;
      }
    }
  }
}
.line{
  border: 1px solid #F4EAE9;
  margin: 0;
  border: none;
}