.wrapperCard {
  margin-bottom: 20px;
  padding: 32px;

  @media (max-width: 720px) {
    padding: 24px 12px;
    margin-bottom: 0;
  }
}

.container {
  margin-top: 32px;

  @media (max-width: 720px) {
    margin-top: 24px;
  }
}

.title {
  color: #333;
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.2px;

  &.disabled {
    color: #999999;
  }
}

.paymentDesc {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 24px;
  @media (max-width: 720px) {
    margin: 0 0 20px;

  }
}

.submitBtn {
  margin-top: 16px;

  @media (max-width: 720px) {
    width: 100%;
    margin-top: 24px;
  }
}

.termsAndConditions {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0 0 32px;
  @media (max-width: 720px) {
    margin: 20px 0 28px;

  }

  & > span {
    color: #691b33;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-decoration-line: underline;
  }

  .redirectToPublicOferta {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: #691b33;
  }
}

div.textArea {
  min-width: 368px;
  margin-bottom: 0;
}

.addComment {
  margin: 0 0 32px 0;
  user-select: none;
  width: fit-content;
  cursor: pointer;
  color: #691b33;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border-bottom: 1px solid #691b33;

  &:hover {
    color: #bc6f87;
    border-color: #bc6f87;
  }

  @media (max-width: 720px) {
    margin: 0;
  }
}

label.checkboxLabel {
  gap: 9px;
}

.checkboxIcon {
  padding: 3px 0 0 2px;
}