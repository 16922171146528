.innerGridItemUser {
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-flow: row;
  margin: 0;

  .rightIcons {
    margin-right: 20px;
    stroke: #000;
    stroke-width: 2;
    display: flex;
    &.hideUser {
      display: none;
    }

    .userInitials {
      text-transform: uppercase;
      border-radius: 50%;
      background-color: #f4eae9;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #691b33;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      // * need to make header height=85px
      margin: -2px 0;
    }
  }
  .cartIconWrapper {
    display: flex;

    .loginBtn {
      padding: 0;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
}
