.greenText {
  color: green;
}

.redText {
  color: red;
}

.blueText {
  color: #54619E;
}

.grayText {
  color: #B4B1B1;
}