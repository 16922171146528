@import "@layout/z-index-layouts.scss";

.appFigure {
  display: flex;
  gap: 16px;
  height: 100%;

  * {
    width: 100%;
  }

  :global(.mz-figure) > img {
    border-radius: 8px;
    max-width: initial !important;
    max-height: 600px !important;
  }
}


.selectors {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 0 134px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  scrollbar-color: #b38d99 transparent;
  padding-right: 4px;

  &::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #333333;
  }

  &::-webkit-scrollbar:active {
    background-color: #691B33;
  }
}


.imgList {
  width: 110px;
  height: 138px;
  border-radius: 8px;
  object-fit: cover;
}

.img {
  border-radius: 12px;
  position: relative;
}

.imgContainer {
  margin: 0;
}

.swiperWrapper {
  display: flex;
  flex-direction: column;

  &:global(.swiper) {
    height: 100%;
  }
}

.container {
  .containerAttributesHeight {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: $zindex-gallery-icon;
    transform: translate(50%, -50%);
    align-items: center;
    gap: 24px;
    top: 50%;
    right: 24px;
    width: max-content;
    writing-mode: vertical-rl;

    .hrHeightTop, .hrHeightBottom {
      transform: rotate(90deg);
    }
  }

  .attributeWidth,
  .attributeHeight {
    background-color: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    padding: 9px 4px;
    width: fit-content;
    text-align: center;
  }

  .attributeHeight {
    padding: 4px 9px;
  }

  hr {
    margin: 0;
    padding: 0;
    width: 32px;
    border: 1px solid #333;
  }

  .containerAttributesWidth {
    display: flex;
    position: absolute;
    z-index: $zindex-gallery-icon;
    width: fit-content;
    left: 50%;
    transform: translate(-50%, 50%);
    align-items: center;
    gap: 8px;
    bottom: 24px;
    text-align: center;
  }

}