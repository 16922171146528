.favoritesLink {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover > * {
    stroke: #bc6f87;
  }

  &:active > * {
    stroke: #bc6f87;
  }
}
