.hr {
  border-left: 1px solid #ebe4e4;
  margin: 0 34px;
}

.stateOrder {
  display: flex;
  align-content: center;
  margin: 0;

  .statusText {
    margin: 0;
    padding-left: 4px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.titleInfoAboutOrder {
  margin: 0 0 32px 0;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  @media (max-width: 720px) {
    padding-top: 24px;
  }
}

.wrapper {
  padding: 32px 32px;
  margin: 0;
  border-radius: 12px;
  background-color: #fff;
  @media (max-width: 720px) {
    margin: 0;
    padding: 24px 12px;
    border-radius: 8px;
  }

  .wrapperTitle {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding-bottom: 32px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    align-items: flex-end;
    @media (max-width: 720px) {
      flex-wrap: wrap;
      padding-bottom: 10px;
      font-size: 20px;
    }

    .orderTitle {
      margin: 0;
      margin-right: 12px;
      color: #333;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 28.8px */
      @media (max-width: 720px) {
        width: 100%;
        padding-bottom: 10px;
        margin-right: 0;
        font-size: 20px;
        line-height: 110%;
      }
    }

    .date {
      margin: 0;
      flex: 1;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      color: #999;
    }
  }
}

.wrapperOrderProductItem {
  display: flex;
  margin-bottom: 32px;
  @media (max-width: 720px) {
    margin-bottom: 25px;
    display: block;
  }

  .innerOrderProductItem {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 720px) {
      border-bottom: 1px solid #ebe4e4;
      padding-bottom: 24px;
    }
  }
}

.btnOrder {
  display: flex;
  align-items: center;
  gap: 5px;
}

.innerBtn {
  display: flex;
  gap: 10px;
}

.wrapperOrderDetails {
  width: 100%;
  @media (max-width: 720px) {
    padding-bottom: 22px;
  }
}

.hrBetweenOrderProductItem {
  border-bottom: 1px solid #ebe4e4;
  padding: 15px 0;
}

.orderActions {
  display: flex;
  align-items: center;

  .payButton {
    margin-left: auto;
  }
}

.subtitle{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin: 0 0 25px 0;
}

.feedbackBtn{
  background: transparent;
  border: none;
}

.feedbackLabel{
  border-bottom: 1px solid #691b33;
}