.phoneBtn {
  padding: 0;

  &:active {
    background-color: transparent;
  }
}

.phone {
  font-size: 24px;
  white-space: nowrap;
  padding-left: 20px;
  font-weight: 500;
  color: #333333;
  margin: 0;

  @media (max-width: 1440px) {
    padding-left: 16px;
  }

  &:hover {
    color: #bc6f87;
  }
}

.arrowExpand {
  stroke: #333333;
  transform: rotate(180deg);
}
.btn {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  font-weight: 500;
  color: #333;
  font-size: 24px;
  cursor: pointer;
  &.btnWithoutPopup {
    padding: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.workHours {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #999;
}

.phoneDefault {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: #333333;
  white-space: nowrap;
  margin: 0;
  &:hover {
    color: #bc6f87;
  }
}
