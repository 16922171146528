.profileTitle {
  margin: 0 0 30px;
  text-align: left;
  @media (max-width: 720px) {
    margin: 0 0 24px;
  }
}

.input {
  min-width: 320px;

  @media (max-width: 350px) {
    min-width: auto;
  }
}

.submitBtn {
  @media (max-width: 720px) {
    width: 100%;
  }
}

.wrapperForm {
  display: flex;
  gap: 12px;

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 0;
  }
}

div.phoneInput {
  margin-bottom: 13px;
}

.formAction {
  display: flex;
  align-items: center;
  gap: 55px;
  margin: 0 0 30px;

  @media (max-width: 720px) {
    margin: 0 0 24px;
    justify-content: space-between;
  }

  .useAsLogin {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin: 0;
  }
}

.submitForm {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 720px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
}

.actions {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #691b33;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  margin-bottom: 32px;
  margin: 0;
}
