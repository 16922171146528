.addressSelectContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 720px) {
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
  }
}

.addressSelect {
  width: 100%;
}

.chooseOnMap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #691B33;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  & > span {
    margin-left: 4px;
  }
  @media (max-width: 720px) {
    margin-left: 0;
  }
}

.workingHours {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 8px 0 0;
  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}

.mapContainer {
  margin-top: 20px;
  height: 260px;
  border-radius: 8px;
  background: #D9D9D9;
}