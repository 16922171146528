.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f2e4df;
}

.loaderSvg {
  width: 80px;
  height: auto;
  animation: loader-animation 2s linear infinite;
}

@keyframes loader-animation {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.6);
  }
}

.loaderPath {
  transition: fill 0.6s;
}
