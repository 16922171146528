@import "@layout/z-index-layouts.scss";

.productPageWrapper {
  background-color: #f4eae9;
  padding: 0 20px 80px;

  @media (max-width: 720px) {
    padding: 0 12px 60px;
  }
}

.productContainer {
  margin: 0 auto;
  max-width: 1222px;
  padding: 32px 24px;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  gap: 40px;

  @media (max-width: 720px) {
    padding: 24px 12px;
    flex-wrap: wrap;
    gap: 0;
  }
}

.favoriteIcon {
  position: absolute;
  right: 0;
  z-index: $zindex-product-card-icon;
  height: 30px;
  width: 30px;

  & > * {
    stroke: #691b33;
  }
}

.highlightIcon {
  left: 170px;
  top: 14px;
  @media (max-width: 960px) {
    top: 12px;
    left: 12px;
  }
}

div.discountIcon {
  left: 170px;
  top: 14px;
  @media (max-width: 720px) {
    left: 10px;
    top: 6px;
  }
}

.productGalleryContainer {
  width: 625px;
  max-height: 600px;
  overflow: hidden;
  position: relative;

  .image {
    width: 100%;
    object-fit: cover;
  }
}

.productInfoContainer {
  // margin-left: 40px;
  flex: 1;

  @media (max-width: 720px) {
    margin-left: 0;
    margin-top: 20px;
  }
}

.deliveryInfo {
  margin: 0 0 24px;
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .deliveryInfoTime {
    font-weight: 500;
    margin-left: 10px;
  }
}

.productName {
  margin: 0;
  color: #333;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  @media (max-width: 720px) {
    margin-top: 20px;
  }
}

.statusAndCodeContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;
  gap: 12px;

  &.statusContainer {
    flex-wrap: wrap;
  }

  .productStatus {
    padding: 0;
  }

  .productCode {
    margin: 0;
    color: #9a999a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 100%;
  }
}

.dimensionsTitle {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin: 24px 0 12px 0;
  @media (max-width: 720px) {
    margin: 20px 0 12px 0;
  }
}

.dimensions {
  display: flex;
  align-items: center;

  @media (max-width: 720px) {
    flex-wrap: wrap;
  }

  .dimensionItem {
    cursor: pointer;
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    background: #f4eae9;
    border: 1px solid #f4eae9;
    white-space: nowrap;

    @media (max-width: 720px) {
      margin-bottom: 8px;
    }

    &.dimensionItemActive {
      border: 1px solid #691b33;
      background: #fff;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.price {
  padding: 0;
  margin-top: 20px;
}

button.redirectToCartBtn {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  border-radius: 6px;
  border: 2px solid #5f6eb2;
  padding: 10px 55px;
  background-color: transparent;
  cursor: pointer;

  .redirectToCart {
    display: flex;
    align-items: center;
    margin: 0;
  }
}

.quickBuyModalWrapper {
  @media (max-width: 720px) {
    flex: 1;
    margin: 16px 0 12px 0;
    text-align: center;
  }
}

.sectionTitle {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 24px 0 12px 0;
  @media (max-width: 720px) {
    margin-top: 20px;
  }
}

.attributesContainer {
  margin-top: 16px;
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;

  @media (max-width: 720px) {
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }
}

.attributeItem {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-right: 1px solid #999;
  padding: 0 11px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 180px;
  text-overflow: ellipsis;

  &:first-of-type {
    padding: 0 11px 0 0;
  }

  &:last-of-type {
    padding: 0 11px 0 11px;
  }
}

.attributesSeparator {
  padding-left: 11px;
  display: flex;
  align-items: center;
  @media (max-width: 720px) {
    padding-left: 0;
  }
}

.deliveryContainer {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  .deliveryToApartment {
    display: flex;
  }

  .deliveryValue {
    color: #999a9a;
  }
}

.descriptionContainer {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.compositionContainer {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.paymentMethods {
  margin-top: 12px;
}

.paymentItem {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-right: 1px solid #999;
  padding: 0 11px;

  &:first-of-type {
    padding: 0 11px 0 0;
  }

  &:last-of-type {
    padding: 0 0 0 11px;
    border: none;
  }
}

.breadcrumbs {
  padding: 30px 0;
  @media (max-width: 720px) {
    padding: 20px 0;
  }
}

.novaPostBlock {
  color: #999a9a;
}

.cartActions {
  display: flex;
  gap: 18px;
  align-items: center;
  flex-direction: row;
  margin-top: 16px;
  @media (max-width: 1280px) {
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;
  }
  @media (max-width: 1080px) {
    justify-content: center;
  }
  @media (max-width: 720px) {
    flex-wrap: wrap;
    gap: 12px;
  }

  &.catalogCategoryProductsGroupAction {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    align-items: flex-start;

    .postcardsValue {
      width: 100%;
      margin: 0;

      textarea {
        max-width: 100%;
      }
    }
  }
}

.preOrderContainer {
  margin: 0;
  display: flex;
  gap: 4px;

  .deliveryShiftDays {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #ff5c32;
    margin: 0;
  }
}

.postcardMessage {
  width: 100%;
  cursor: pointer;
}
