.cardContainer {
  height: 360px;
  cursor: pointer;
  width: 100%;

  @media (max-width: 1440px) {
    height: 280px;
  }

  @media (max-width: 1280px) {
    height: 232px;
  }

  &:hover {
    .imageContainer,
    .infoContainer {
      border-color: #691b33;
    }

    .infoContainer {
      border-left-color: #ebe4e4;
    }
  }
}

.cardLink {
  text-decoration: none;
  color: inherit;
  outline: none;
  display: flex;
  width: 100%;
}

.imageContainer {
  width: 360px;
  height: 360px;
  border-radius: 12px 0 0 12px;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: #ebe4e4;
  background-color: #ffffff;
  position: relative;

  @media (max-width: 1440px) {
    height: 280px;
    width: 280px;
  }

  @media (max-width: 1280px) {
    height: 232px;
    width: 232px;
  }

  @media (max-width: 720px) {
    border-radius: 8px 0px 0px 8px;
    width: 132px;
  }
}

.image {
  object-fit: cover;
  border-radius: 12px 0px 0px 12px;

  @media (max-width: 720px) {
    border-radius: 8px 0px 0px 8px;
  }
}

.infoContainer {
  padding: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: #ebe4e4;
  border-radius: 0px 12px 12px 0px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  & > p {
    flex: initial;
  }

  @media (max-width: 720px) {
    border-radius: 0px 8px 8px 0px;
  }
}

.cardFooter {
  width: 100%;
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 720px) {
    padding: 6px 0;
    justify-content: space-between;
  }
}

.cardFooterPrice {
  margin-left: 12px;

  padding: 12px 0;

  @media (max-width: 720px) {
    padding: 8px 0;
    margin-left: 6px;
    white-space: nowrap;
  }
}

.buyButton {
  padding: 0;
  border-radius: 50px;
  background-color: #5f6eb2;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  line-height: 24px;
  box-sizing: border-box;

  & > * {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 720px) {
    width: 40px;
    height: 40px;
    margin-right: 6px;
    line-height: 20px;

    & > * {
      width: 20px;
      height: 20px;
    }
  }

  svg {
    stroke: initial;
  }

  &:active {
    outline: none;
    background: none;
  }

  &:hover {
    background-color: #7983b0;
  }

  &:active {
    background-color: #404e90;
  }

  &:disabled {
    background-color: #5f6eb2;
  }

  &.buyButtonInCart {
    background-color: #fff;
    border: 1px solid #5f6eb2;

    &:active {
      outline: none;
      background: none;
    }

    &:hover {
      border-color: #7982b0;
      opacity: 0.9;
    }

    &:active {
      border-color: #414e91;
      opacity: 1;
    }

    &:disabled {
      border-color: #9ea6c9;
    }
  }
}

.favoriteIcon {
  top: 14px;
  right: 14px;
  @media (max-width: 720px) {
    right: 4px;
  }
}


.highlightIcon {
  @media (max-width: 720px) {
    left: 10px;
  }
}

.swiper {
  height: 100%;
}