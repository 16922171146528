// TODO remove specificity after remove material
span.root {
  height: 4px;
  width: calc(100% - 24px);
  margin-left: 12px;
  box-sizing: border-box;
}

.track {
  background: #691b33;
  color: #691b33;
  height: 4px;
  border-radius: 2px;
}

.rail {
  height: 4px;
  width: calc(100% + 24px);
  margin-left: -12px;
  background: #333333;
  border-radius: 2px;
}

.active {
  box-shadow: none;

  &:hover .thumb {
    background: #450f20;
  }

  &.thumb {
    background: #450f20;
  }
}

span.thumb {
  height: 24px;
  width: 24px;
  background: #691b33;
  color: #691b33;

  &:hover {
    background: #450f20;
    box-shadow: none;
  }

  &:active {
    background: #691b33;
    box-shadow: none;
  }
}

.mark {
  display: none;
}

.markLabel {
  display: none;
}

.container{
  display: flex;
  gap: 4px;
}