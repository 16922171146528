@import "@layout/z-index-layouts.scss";

.mobileHeader {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 16px 12px;

  .headerLogo {
    max-width: 120px;
  }
}

.wrapper {
  padding: 0;
}

.mobileContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: auto;
  gap: 24px;
  line-height: 0;
}

.header {
  @media (max-width: 720px) {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: $zindex-header;
  }
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: $zindex-header;
  background-color: white;
}

.headerTopHidden {
  transform: translateY(-100%);
  transition: transform 0.5s ease;
}

.headerTopVisible {
  transform: initial;
  transition: transform 0.5s ease;
}
