.emptyCart {
  text-align: center;
  padding: 24px 0;

  @media (max-width: 500px) {
    padding: 24px;
  }

  .emptyCartTitle {
    color: #333;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0 0 24px;
  }

  .emptyCartInfo {
    color: #333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}