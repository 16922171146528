.delivery {
  width: 100%;
}

.orderDetailsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  @media (max-width: 300px) {
    display: block;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.hrOrderDetails {
  border-bottom: 1px solid #ebe4e4;
  margin: 20px 0 32px 0;
  @media (max-width: 720px) {
    margin: 24px 0 24px 0;

  }
}

.wrapperTtn {
  align-items: self-end;

  p.ttn {
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    vertical-align: center;
  }
}

.userInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #a6a6a6;
  width: 35%;
  margin: 0;
}


.orderDetails {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  width: 60%;
  margin: 0;
}

@media (max-width: 1290px) {

  .userInfo,
  .orderDetails {
    width: 100%;
  }

  .orderDetails {
    flex-wrap: wrap;
  }
}

@media (max-width: 480px) {
  .userInfo {
    width: 50%;
  }

  .orderDetails {
    width: 50%;
    @media (max-width: 720px) {
      word-break: break-word;
    }
  }

  .orderDetailsWrapper {
    justify-content: normal;
  }
}

.containerNotes {
  display: block;

  .notesLabel {
    margin-bottom: 4px;
  }

  .notesMessage {
    word-break: break-word;
    width: 100%;
  }
}