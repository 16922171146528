.container {
  margin-top: 14px;
  @media (max-width: 720px) {
    margin-top: 30px;
  }
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

.links {
  display: flex;
  align-items: center;
  gap: 16px;
}