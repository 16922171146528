.ordersListWrapper {
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 15px 24px;
  cursor: pointer;

  &.orderCanceled {
    opacity: 0.7;
  }

  @media(max-width: 720px) {
    display: block;
    padding: 15px 12px;
  }

  .wrapperNumberOrder {
    flex: 2;
    display: flex;
    gap: 20px;
    @media (max-width: 720px) {
      margin-bottom: 12px;
    }

    .titleOrder {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: #333;
      flex: 1;
      margin: 0;
      @media (max-width: 720px) {
        flex: 1;
      }
    }

    .dataOrder {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
      flex: 1;
      margin: 0;
      display: flex;
      align-items: center;
      @media(max-width: 720px){
        flex: 0;
      }
    }
  }

  .wrapperCountAndPrice {
    flex: 1.5;
    @media (max-width: 720px) {
      flex: 1;
      margin: 0;
    }

    .countProduct {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #333;
    }

    .priceProduct {
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: #333;
    }
  }

  .wrapperStateAndImg {
    display: flex;
    flex: 2;
    @media (max-width: 720px) {
      width: 100%;
    }

    .stateOrderList {
      display: flex;
      align-items: center;
      gap: 4px;
      flex: 2;

      & .stateText {
        margin: 0;
      }
    }

    .remainingImages {
      position: absolute;
      top: 12px;
      left: 80px;
      font-size: 13px;
      font-weight: 500;
      line-height: 16px;
      color: #fff;
      background-color: #691B33;
      padding: 2px;
      border-radius: 4px;
      width: max-content;
    }

    .wrapperImg {
      display: flex;
      align-items: center;
      gap: 19px;
      position: relative;

      .imgOrdersList {
        height: 40px;
        width: 40px;
        border-radius: 4px;
      }
    }
  }
}


