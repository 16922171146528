.error {
  margin-top: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > *:first-of-type {
    color: #333;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: 0.2px;
  }

  .editLink {
    color: #691b33;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    &:hover {
      color: #BC6F87;
      border-color: #BC6F87;
    }
  }
}

.itemsContainer {
  margin-top: 16px;
}

.footer {
  margin-top: 24px;
}
