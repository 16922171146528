.container {
  padding: 12px 20px;

  .phoneNumber {
    padding: 0;
    margin-top: 0;
    margin-bottom: 10px;
    color: #333;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
  }
}