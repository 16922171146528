@import '@layout/z-index-layouts';

.swiperWrapper {
  &:global(.swiper) {
    overflow: visible;
  }
}

.swiperNavBtn {
  .sliderButton {
    z-index: $zindex-regular-icon;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 19px 16px;
    border-radius: 6px;
    border: 1px solid #ebe4e4;
    background: #fff;
    cursor: pointer;
    transform: translate(-50%, -50%);
    top: 50%;

    @media (max-width: 768px) {
      display: none;
    }
    &:hover {
      background-color: #f5f2f2;
      stroke: #bc6f87;
    }

    &:active {
      background-color: #ebe4e4;
      stroke: #691b33;
    }
  }

  .sliderButtonNext {
    left: 32px;
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .sliderButtonPrev {
    right: -12px;
  }
}

.customPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;

  & > * {
    width: max-content;
  }

  & > :global(.swiper-pagination-bullet) {
    background-color: #ebe4e4;
    width: 7px;
    height: 7px;
    opacity: 1;
  }

  & > :global(.swiper-pagination) {
    width: max-content;
  }

  & > :global(.swiper-pagination-bullet-active) {
    background-color: #691b33;
    width: 10px;
    height: 10px;
  }
}
