.paginationContainer {
  display: flex;
  margin: 0 auto 20px;
  flex-direction: column;

  @media (max-width: 720px) {
    margin: 14px auto 20px;
  }

  .item {
    color: #691b33;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  .itemSelected {
    border-radius: 4px;
    background: #fff;
  }

  .showMoreBtn {
    color: #691b33;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    padding: 0;
    margin: 0 auto 16px;

    &:disabled {
      color: #999999;
    }

    &:hover {
      background-color: transparent;
    }

    @media (max-width: 720px) {
      margin-bottom: 12px;
    }
  }

  .showMoreIcon {
    transform: rotate(90deg);
    margin-right: 10px;
    & > svg {
      stroke: #691b33;
      stroke-width: 1px;
    }
    &.showMoreIconDisabled {
      & > svg {
        stroke: #999999;
      }
    }
  }
}
