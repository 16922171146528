.wrapper {
  margin-bottom: 30px;
  @media (max-width: 720px) {
    margin-bottom: 20px;
  }
}

.totalDocs {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.titleContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media (max-width: 720px) {
    align-items: flex-end;
  }
}

.title {
  color: #333;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 24px 0 32px 0;
  @media (max-width: 720px) {
    font-size: 24px;
    margin: 20px 0 0 0;
    padding: 0;
  }
}

.breadcrumbs {
  padding-top: 30px;
  @media (max-width: 720px) {
    padding-top: 20px;
  }
}

.imagesWrapper {
  margin-top: 30px;
  padding: 0 20px;

  @media (max-width: 720px) {
    margin-top: 20px;
    padding: 0;
  }
}

.containerImg {
  display: flex;
  justify-content: center;
  max-height: 220px;
  max-width: 1760px;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 auto;

  img {
    max-width: 50%;
    flex: 1;
    object-fit: cover;

    @media (max-width: 720px) {
      max-width: 100%;
      object-fit: contain;
      height: auto;
    }
  }
}

.container {
  margin-top: 30px;
  @media (max-width: 720px) {
    margin-top: 20px;
  }
}

.imgDesktop {
  @media (max-width: 720px) {
    display: none;
  }
}

.imgMobile {
  @media (min-width: 720px) {
    display: none;
  }
}