.wrapper {
  background-color: #f4eae9;
  width: 100%;
  padding: 80px 0;

  @media (max-width: 720px) {
    padding: 60px 12px;
  }

  .container {
    max-width: 1100px;
    margin: 0 auto;

    @media (max-width: 720px) {
      width: 100%;
    }

    .contentWrapper {
      position: relative;

      .content {
        display: -webkit-box;
        -webkit-line-clamp: 9;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.3s;
        @media (max-width: 720px) {
          -webkit-line-clamp: 15;
        }
      }

      .toggleCheckbox {
        display: none;

        &:checked ~ .content {
          -webkit-line-clamp: unset;
          overflow: visible;
        }

        &:checked ~ .toggleButton {
          .expandText {
            display: none;
          }

          .collapseText {
            display: block;
          }
        }
      }

      .toggleButton {
        display: inline-block;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        margin-top: 16px;
        text-decoration: underline;
        color: #691b33;

        &:hover {
          color: #bc6f87;
          border-color: #bc6f87;
        }

        .expandText {
          display: inline;
        }

        .collapseText {
          display: none;
        }
      }
    }
  }
}

.spaceBetween {
  margin-top: 32px;
}
