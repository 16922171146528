.chip {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  padding: 8px;
  color: #691b33;
  background-color: #fff;
  max-width: fit-content;
  cursor: pointer;
  white-space: nowrap;

  &.inactive {
    opacity: 0.7;
  }

  @media (max-width: 720px) {
    height: 23px;
  }
}

.chipIcon {
  color: #450f20;
  display: flex;
  padding: 0;

  &:hover {
    color: #bc6f87;

    & > svg {
      color: #bc6f87;
    }
  }

  &:active {
    color: #450f20;

    & > svg {
      color: #450f20;
    }
  }

  & > svg {
    color: #691b33;
  }
}
