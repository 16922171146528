.clientAndBlog,
.currency,
.containerFlag,
.languageIcon {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #333333;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.cartIconWrapper {
  display: flex;

  .loginBtn {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.userInitials {
  text-transform: uppercase;
  border-radius: 50%;
  background-color: #f4eae9;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #691b33;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  // * need to make header height=85px
  margin: -2px 0;
}

.containerFlag {
  display: flex;
  align-items: center;
  margin-left: 21px;
}

.clientAndBlogWrapper {
  padding-left: 40px;
  @media (max-width: 1440px) {
    padding-left: 24px;
  }
}

.headerWrapper {
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  color: #000000;
  line-height: 18px;
  border-bottom: 1px solid #ebe4e4;
  @media (max-width: 1300px) {
    margin: 0 20px;
    padding: 24px 0;
  }

  .gridItem {
    display: flex;
    &.hideGridItem {
      display: none;
    }

    & > .headerInfo,
    .innerGridItemCity,
    .innerGridItemUser {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
  }

  .rightIcons {
    margin-right: 20px;
    stroke: #000;
    stroke-width: 2;
    display: flex;
  }
}

.headerInfo {
  gap: 60px;
  @media (max-width: 1440px) {
    gap: 32px;
  }
  @media (max-width: 1300px) {
    gap: 44px;
  }
}

.infoWrapper {
  flex: 1;
  align-items: center;
  overflow: hidden;
}

.mainLogoWrapper {
  flex-basis: 200px;
  justify-content: center;
}

.userSettingsWrapper {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}

.innerGridItemCity {
  display: flex;

  align-items: center;
  justify-content: space-evenly;
  flex-flow: row;
  width: 100%;
  margin: 0;
}

.innerGridItemUser {
  display: flex;
  align-items: center;
  margin: 0;
  flex-flow: row;
}

.search {
  width: 100px;
  margin-left: 0;
  margin-right: 60px;
  height: 40px;
  @media (max-width: 1440px) {
    margin-right: 44px;
  }
  &.searchOpen {
    width: 100%;
    margin-right: 0;
  }
}

.hide {
  display: none;
}

.companyInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}
