
.dialogTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #333333;
  margin-bottom: 12px;
  text-align: center;
  margin: 0 0 25px;
}

.error {
  margin-top: 10px;
}
