.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 20px;
  margin-bottom: 30px;

  @media (max-width: 1586px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1275px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 720px) {
    margin-bottom: 24px;
    gap: 0;
  }

  @media (max-width: 370px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.paginationWrapper {
  margin-top: 32px;
}

.itemNotFound {
  width: 100%;
  margin: 10% 0 15px 0;
  text-align: center;
}
