.title {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.serviceFeedbackBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
}