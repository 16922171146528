.socialBlock {
  display: flex;
  filter: grayscale(100%);
}

.btnSocial {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #333;
  font-style: normal;
  line-height: 120%;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}