@import "@layout/z-index-layouts.scss";

.productName {
  position: relative;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  width: 220px;
  word-wrap: break-word;
  margin: 0;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 375px) {
    width: 100%;
  }

  .tooltipText {
    visibility: hidden;
    width: 100%;
    background-color: #f4eae9;
    color: #333;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: $zindex-regular-tooltip;
    top: 150%;
    left: 25%;
    margin-left: -60px;
  }
}

.productName .tooltipText::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.productName:hover .tooltipText {
  visibility: visible;
}
