.container {
  width: 100%;
  padding: 80px 0;

  &.background {
    background-color: #F4EAE9;
  }
  &.withoutPadding {
    padding-top: 0;
  }
  @media (max-width: 720px) {
    padding: 60px 12px;
  }
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  max-width: 1222px;
  margin: 0 auto;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.content {
  border-radius: 6px;
  border: 1px solid #e1d2d6;
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #333;
  white-space: nowrap;
}
