.wrapper {
  padding: 0 20px;

  @media (max-width: 720px) {
    padding: 0 12px;
  }

  .container {
    max-width: 1760px;
    margin: 0 auto;
    display: flex;
    gap: 20px;

    @media (max-width: 720px) {
      flex-direction: column;
      gap: 0;
    }

    .filters {
      max-width: 335px;
      min-width: 271px;
      flex: 1;
      margin-bottom: 20px;
    }
  }
}

div.wrapperWithOutFilterBar {
  padding: 0;
}
