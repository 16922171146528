.clientAndBlog {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #333333;
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover{
    color: #bc6f87;
  }
  @media (max-width: 1280px) {
    display: none;
  }
}
.links{
  display: flex;
  flex-direction: column;
  gap: 10px;
  .link{
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
}
.arrowExpand{
  transform: rotate(180deg);
  &>svg{
    height: 18px;
  }
}