.container {
  border: 1px solid #ebe4e4;
  border-radius: 6px;
  width: 102px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .qty {
    color: #333;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  button {
    cursor: pointer;
    outline: none;
    padding: 0;
    border: none;
    background: none;
    font-size: 0;
    flex-shrink: 0;
    color: #333333;

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
