@import "@layout/z-index-layouts.scss";

.searchWrapper {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 62px;
}

.fullWidth {
  width: 100%;
}

.wrapper {
  margin-right: 20px;
  height: 60px;
}

.searchContainer {
  position: absolute;
  z-index: $zindex-autocomplete;
  max-width: 868px;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.searchIcon {
  cursor: pointer;
  &:hover {
    color: #bc6f87;
  }
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #999;
}
