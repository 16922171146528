.paginationContainer {
  display: flex;
  margin: 0 auto 20px;
  flex-direction: column;

  @media (max-width: 720px) {
    margin: 14px auto 20px;
  }

  .item {
    color: #691b33;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    align-items: center;
  }

  .itemSelected {
    border-radius: 4px;
    background: #fff;
  }
}

.paginationList {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin: 0;
  padding: 0;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
}

.paginationItem {
  padding: 0;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #691b33;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 22px;
  &.arrow {
    width: 40px;
    &:first-of-type {
      padding-left: 0;
      justify-content: flex-start;
    }
  }
  &:hover {
    background-color: #e7e0df;
  }
  &.disabled {
    cursor: not-allowed;
    & > svg > g {
      stroke: #ccc;
    }
    &:hover {
      background-color: transparent;
    }
  }
}

.disabledArrow {
  & > svg > g {
    stroke: #ccc;
  }
}

.active {
  background: #fff;
  border-radius: 4px;
}

.paginationEllipsis {
  cursor: default;
  user-select: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
