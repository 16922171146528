.ordersContainer {
  border-radius: 12px;
}

.titleOrder {
  margin-top: 0;
  @media (max-width: 720px) {
    margin-top: 10px;
  }
}

.pagination {
  margin: 32px 0 0 0;
  align-items: center;
}