.contactsContainer {

  .title {
    margin-top: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  .phoneContainer {
    margin: 24px 0 20px;

    .phoneNumber {
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      color: #333333;
    }
  }

  .phoneDescription {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #a8a8a8;
    margin-left: 12px;
  }

  .workingHours {
    & > p {
      margin: 0;
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}