.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainLogoLink {
  @media (max-width: 720px) {
    display: none;
  }
}

.copyRight {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}