.wrapper {
  display: block;
  max-width: 412px;

  @media (max-width: 720px) {
    width: 100%;
  }
}

.container {
  display: flex;
  margin-bottom: 18px;
  @media (max-width: 350px) {
    display: block;
  }
  &:last-of-type {
    margin-bottom: 24px;
  }
}

.profileTitle {
  margin-bottom: 30px;
  margin-top: 0;
  text-align: left;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;

  @media (max-width: 720px) {
    margin-bottom: 24px;
    font-size: 20px;
  }
}

.userInfo {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #a6a6a6;
  text-align: left;
  flex: 1;
  margin: 0;

  @media (max-width: 720px) {
    font-size: 14px;
  }
}

.orderDetails {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  text-align: left;
  flex: 1.3;
  margin: 0;
  @media (max-width: 720px) {
    font-size: 14px;
    flex: 1.5;
  }
}

.profileActions {
  text-align: left;
  padding-bottom: 32px;

  @media (max-width: 720px) {
    padding-bottom: 24px;
  }
  @media (max-width: 300px) {
  text-align: center
  }
  & > *:not(:first-of-type) {
    margin-left: 32px;
    @media (max-width: 300px) {
      margin-left: 12px;
    }
    @media (max-width: 260px) {
      text-align: center;
      margin-top: 8px;
    }
  }
}