.registerTitle {
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.subscription {
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;

  .checkBox {
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }

  .checkBox:hover {
    background: #691b33;
  }

  .checkBox:checked {
    accent-color: #691b33;
  }

  label {
    color: #333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

.signIn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .signInTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }

  .signInLink {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.42px;
  }
}
