$spacing-unit: 8px; // Define your theme's spacing unit here
$common-white: #fff; // Define your theme's common white color here
$common-lines: #bdbdbd; // Define your theme's common lines color here
$text-secondary: #757575; // Define your theme's text secondary color here
$common-black: #000; // Define your theme's common black color here
$text-primary: #212121; // Define your theme's text primary color here
$shadow-cardHover: 0px 5px 15px rgba(0, 0, 0, 0.08); // Define your theme's card hover shadow here

.filtersSectionWrapper {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;

  .filtersContainer {
    padding: 24px 19px 24px 12px;
    width: 100%;

    // TODO scrollbar ??
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.25) transparent;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 20px;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.15);
    }
    @media (max-width: 720px) {
      padding: 24px 19px 54px 12px;
    }
  }
}

.devicePanel {
  background-color: $common-white;
  padding-top: $spacing-unit * 2;
  padding-bottom: $spacing-unit * 2;
}

.backButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paddedExpandPanel {
  & > div {
    padding-top: $spacing-unit * 1.5;
    border-top: 1px solid $common-lines;
  }

  & > div:last-of-type {
    padding-bottom: 10px;
    border-top: 1px solid $common-lines;
  }
}

.paddedPanelWrapper {
  padding-top: $spacing-unit * 1.5;
  border-top: 1px solid $common-lines;
}

.expandedList {
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #fff;
  }

  //@todo remove after refactoring ExpandPanel
  & > li > div > div > label {
    & > span:nth-child(1) {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0;
      border: 1px;
      margin: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      padding: 5px;
      border-radius: 50%;
      opacity: 1;
      color: #691b33;

      &:hover {
        background-color: #fff;
      }
    }

    //@todo remove after refactoring ExpandPanel
    & > span:nth-child(2) {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      color: #333;
      font-family:
        "Cera Pro",
        "IBM Plex Sans",
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        Arial,
        sans-serif;
    }
  }
}

.collapseAllList {
  margin-top: $spacing-unit;
  margin-bottom: $spacing-unit;
  width: fit-content;
}

.collapseAllButton {
  z-index: 2;
  width: fit-content;
  padding: $spacing-unit 0;
}

.TreeItem {
  color: $text-secondary;
}

.TreeItemActive {
  color: $common-black;
}

.TreeSubItemActive {
  color: $text-primary;
}

.TreeItemHideIcon {
  display: none;
}

.FilterWrapper {
  & .MuiFormControlLabel-root {
    margin-left: $spacing-unit * -1;
    margin-right: $spacing-unit;
  }
}

.TopToolbar {
  margin-top: $spacing-unit * 1.5;
  margin-bottom: $spacing-unit * 1.5;
  margin-right: $spacing-unit * 1.5;
  margin-left: $spacing-unit * 3;

  & .MuiFormControlLabel-root {
    margin-top: $spacing-unit * 1.5;
  }

  & .MuiInputLabel-outlined {
    top: 5px;
  }
}

.expansionSummary {
  align-items: flex-start;
  height: auto;

  & > div {
    display: block;
    margin: 0 !important;
  }
}

.expansionHeader {
  margin-top: 0;
  margin-right: auto;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  user-select: none;
}

.expansionDesc {
  margin: 0;
}

.expansionReset {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  width: fit-content;
}

.expansionResetActive {
  visibility: visible;
  opacity: 1;
}

.expansionBottom {
  width: calc(100% + 4px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}

.expansionBottomMargin {
  margin-bottom: 10px;
  width: 100%;
}

.simpleBottom {
  width: 100%;
  margin-bottom: $spacing-unit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}

.colorLabel {
  display: flex;
  align-items: center;
}

.colorIco {
  margin-left: $spacing-unit;
  margin-right: $spacing-unit;
  width: 16px;
  height: 16px;
  border: 1px solid $common-lines;
  border-radius: 50%;
}

//@todo remove after refactoring ExpandPanel and TreeView, TreeItem
.accordionDetails {
  & > ul > li > div {
    &:hover {
      background-color: #fff;
    }

    & > * {
      background-color: #fff;
    }
    &:global(.MuiTreeItem-content.Mui-selected) {
      background-color: transparent;
    }
  }

  & > label {
    padding-left: 4px;

    & > span:nth-child(1) {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0;
      border: 1px;
      margin: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      padding: 5px;
      border-radius: 50%;
      opacity: 1;
      color: #691b33;

      &:hover {
        background-color: #fff;
      }
    }

    & > span:nth-child(2) {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      color: #333;
      font-family:
        "Cera Pro",
        "IBM Plex Sans",
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        Arial,
        sans-serif;
    }
  }

  & > ul > li > div > div > label {
    & > span:nth-child(1) {
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
      outline: 0;
      border: 1px;
      margin: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      padding: 5px;
      border-radius: 50%;
      opacity: 1;
      color: #691b33;

      &:hover {
        background-color: #fff;
      }
    }

    & > span:nth-child(2) {
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.4;
      color: #333;
      font-family:
        "Cera Pro",
        "IBM Plex Sans",
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        Arial,
        sans-serif;
    }
  }
}
