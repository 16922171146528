.addressSelectContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}

.selectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.addressSelect {
  width: 100%;
  margin-bottom: 0;
}

.chooseOnMap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  cursor: pointer;
  color: #691b33;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 12px 0;

  & > span {
    margin-left: 4px;
    margin-right: 8px;
  }
}

.workingHours {
  color: #333;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin: 0;
}

.datePicker {
  width: 70%;
  margin-bottom: 20px;
  @media (max-width: 720px) {
    width: 100%;
  }
}

.mapContainer {
  margin: 0 0 20px;
  height: 260px;
  border-radius: 8px;
  background: #d9d9d9;

  @media (max-width: 720px) {
    height: auto;
  }
}

.selectInputBase {
  width: 50%;
  margin-bottom: 0;

  @media (max-width: 720px) {
    width: 100%;
  }
}
