.wrapper {
  .container {
    max-width: 1760px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    @media (max-width: 1760px) {
      padding: 0 20px;
    }
    @media (max-width: 720px) {
      padding: 0 12px;
    }
  }
}

.productCardContainer {
  @media (max-width: 720px) {
    min-width: initial;
    max-width: initial;
    width: calc(100% - 12px);

    &:nth-child(odd) {
      margin: 0 auto 6px;
    }
  }
}
