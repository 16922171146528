.wrapper {
  display: flex;
  align-items: center;
  background-color: #691b33;
  padding: 12px;
  width: 100%;
  max-height: 42px;
  @media (min-width: 720px) {
    display: none;
  }
}

.label {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #fff;
}

.link {
  margin-left: auto;
  margin-right: 8px;
  color: #ff5c32;
  text-decoration: underline;
}

.iconClose {
  display: flex;
  padding-left: 2px;
  & > svg > path {
    stroke: #fff;
  }
}
