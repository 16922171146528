.footerWrapper {
  padding: 0 20px;

  @media (max-width: 960px) {
    padding: 0 12px 62px;
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    max-width: 1760px;
    margin: 0 auto;
    padding: 32px 0;

    @media (max-width: 960px) {
      padding: 24px 0;
      display: block;
    }
  }
}
